// Notes!
// Sass color functions, "darken" and "lighten" are automatically replaced.

.glitch.local-settings {
  background: $ui-base-color;

  &__navigation {
    background: darken($ui-base-color, 8%);
  }

  &__navigation__item {
    background: darken($ui-base-color, 8%);

    &:hover {
      background: $ui-base-color;
    }
  }
}

.notification__dismiss-overlay {
  .wrappy {
    box-shadow: unset;
  }

  .ckbox {
    text-shadow: unset;
  }
}

.status.status-direct {
  background: darken($ui-base-color, 8%);
  border-bottom-color: darken($ui-base-color, 12%);

  &.collapsed> .status__content:after {
    background: linear-gradient(rgba(darken($ui-base-color, 8%), 0), rgba(darken($ui-base-color, 8%), 1));
  }
}

.focusable:focus.status.status-direct {
  background: darken($ui-base-color, 4%);

  &.collapsed> .status__content:after {
    background: linear-gradient(rgba(darken($ui-base-color, 4%), 0), rgba(darken($ui-base-color, 4%), 1));
  }
}

// Change columns' default background colors
.column {
  > .scrollable {
    background: darken($ui-base-color, 13%);
  }
}

.status.collapsed .status__content:after {
  background: linear-gradient(rgba(darken($ui-base-color, 13%), 0), rgba(darken($ui-base-color, 13%), 1));
}

.drawer__inner {
  background: $ui-base-color;
}

.drawer__inner__mastodon {
  background: $ui-base-color url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color(darken($ui-base-color, 13%))}"/></svg>') no-repeat bottom / 100% auto !important;

  .mastodon {
    filter: contrast(75%) brightness(75%) !important;
  }
}

// Change the default appearance of the content warning button
.status__content {

  .status__content__spoiler-link {

    background: lighten($ui-base-color, 30%);

    &:hover {
      background: lighten($ui-base-color, 35%);
      text-decoration: none;
    }

  }

}

// Change the background colors of media and video spoilers
.media-spoiler,
.video-player__spoiler,
.account-gallery__item a {
  background: $ui-base-color;
}

// Change the colors used in the dropdown menu
.dropdown-menu {
  background: $ui-base-color;
}

.dropdown-menu__arrow {

  &.left {
    border-left-color: $ui-base-color;
  }

  &.top {
    border-top-color: $ui-base-color;
  }

  &.bottom {
    border-bottom-color: $ui-base-color;
  }

  &.right {
    border-right-color: $ui-base-color;
  }

}

.dropdown-menu__item {
  a,
  button {
    background: $ui-base-color;
    color: $ui-secondary-color;
  }
}

// Change the default color of several parts of the compose form
.composer {

  .composer--spoiler input, .compose-form__autosuggest-wrapper textarea {
    color: lighten($ui-base-color, 80%);

    &:disabled { background: lighten($simple-background-color, 10%) }

    &::placeholder {
      color: lighten($ui-base-color, 70%);
    }
  }

  .composer--options-wrapper {
    background: lighten($ui-base-color, 10%);
  }

  .composer--options > hr {
    display: none;
  }

  .composer--options--dropdown--content--item {
    color: $ui-primary-color;
    
    strong {
      color: $ui-primary-color;
    }

  }

}

.composer--upload_form--actions .icon-button {
  color: lighten($white, 7%);

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
}

.dropdown-menu__separator,
.dropdown-menu__item.edited-timestamp__history__item,
.dropdown-menu__container__header,
.compare-history-modal .report-modal__target,
.report-dialog-modal .poll__option.dialog-option {
  border-bottom-color: lighten($ui-base-color, 12%);
}

.report-dialog-modal__container {
  border-bottom-color: lighten($ui-base-color, 12%);
}

.status__content,
.reply-indicator__content {
  a {
    color: $highlight-text-color;
  }
}

.emoji-mart-bar {
  border-color: darken($ui-base-color, 4%);

  &:first-child {
    background: lighten($ui-base-color, 10%);
  }
}

.emoji-mart-search input {
  background: rgba($ui-base-color, 0.3);
  border-color: $ui-base-color;
}

.autosuggest-textarea__suggestions {
  background: lighten($ui-base-color, 10%)
}

.autosuggest-textarea__suggestions__item {
  &:hover,
  &:focus,
  &:active,
  &.selected {
    background: darken($ui-base-color, 4%);
  }
}

.react-toggle-track {
  background: $ui-secondary-color;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: lighten($ui-secondary-color, 10%);
}

.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: darken($ui-highlight-color, 10%);
}

// Change the background colors of modals
.actions-modal,
.boost-modal,
.favourite-modal,
.confirmation-modal,
.mute-modal,
.block-modal,
.report-modal,
.report-dialog-modal,
.embed-modal,
.error-modal,
.onboarding-modal,
.compare-history-modal,
.report-modal__comment .setting-text__wrapper,
.report-modal__comment .setting-text,
.report-dialog-modal__textarea {
  background: $white;
  border: 1px solid lighten($ui-base-color, 8%);
}

.report-dialog-modal .dialog-option .poll__input {
  color: $white;
}

.report-modal__comment {
  border-right-color: lighten($ui-base-color, 8%);
}

.report-modal__container {
  border-top-color: lighten($ui-base-color, 8%);
}

.boost-modal__action-bar,
.favourite-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar,
.block-modal__action-bar,
.onboarding-modal__paginator,
.error-modal__footer {
  background: darken($ui-base-color, 6%);

  .onboarding-modal__nav,
  .error-modal__nav {
    &:hover,
    &:focus,
    &:active {
      background-color: darken($ui-base-color, 12%);
    }
  }
}

// Change the default color used for the text in an empty column or on the error column
.empty-column-indicator,
.error-column {
  color: lighten($ui-base-color, 60%);
}

// Change the default colors used on some parts of the profile pages
.activity-stream-tabs {

  background: $account-background-color;

  a {
    &.active {
      color: $ui-primary-color;
      }
  }

}

.activity-stream {

  .entry {
    background: $account-background-color;
  }

  .status.light {

    .status__content {
      color: $primary-text-color;
    }

    .display-name {
      strong {
        color: $primary-text-color;
      }
    }

  }

}

.accounts-grid {
  .account-grid-card {

    .controls {
      .icon-button {
        color: $ui-secondary-color;
      }
    }

    .name {
      a {
        color: $primary-text-color;
      }
    }

    .username {
      color: $ui-secondary-color;
    }

    .account__header__content {
      color: $primary-text-color;
    }

  }
}

.button.logo-button {
  color: $white;

  svg {
    fill: $white;
  }
}

.public-layout {
  .header,
  .public-account-header,
  .public-account-bio {
    box-shadow: none;
  }

  .header {
    background: lighten($ui-base-color, 12%);
  }

  .public-account-header {
    &__image {
      background: lighten($ui-base-color, 12%);

      &::after {
        box-shadow: none;
      }
    }

    &__tabs {
      &__name {
        h1,
        h1 small {
          color: $white;
        }
      }
    }
  }
}

.account__section-headline a.active::after {
  border-color: transparent transparent $white;
}

.hero-widget,
.box-widget,
.contact-widget,
.landing-page__information.contact-widget,
.moved-account-widget,
.memoriam-widget,
.activity-stream,
.nothing-here,
.directory__tag > a,
.directory__tag > div {
  box-shadow: none;
}

.mute-modal select {
  border: 1px solid lighten($ui-base-color, 8%);
  background: $simple-background-color url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(lighten($ui-base-color, 8%))}'/></svg>") no-repeat right 8px center / auto 16px;
}
